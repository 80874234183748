import { Typography } from "@mui/material";
import type { NextPage } from "next";
import styled from "styled-components";
import Image, { ImageLoaderProps } from "next/image";
import ImageBL from "../component/ImageBL";
import Head from "next/head";

const Content = styled.div`
  width: 80%;
  margin: 0 auto;
  @media (max-width: 650px) {
    width: 98%;
  }
  .container {
    margin-bottom: 2rem;
    text-align: center;
  }
`;

const Article = styled.div<{
  borderColor?: string;
  firstLetterColor?: string;
}>`
  border: 5px solid ${(props) => props.borderColor};
  border-radius: 15px;
  padding: 1.5rem;
  margin-bottom: 3rem;
  text-align: center;
  z-index: 10;
  position: relative;
  background-color: white;
  p {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    ::first-letter {
      /* margin-left: 2.5rem; */
      font-size: 3rem;
      color: ${(props) => props.firstLetterColor};
    }
  }
`;

const Home: NextPage = () => {
  const myLoader = ({ src }: ImageLoaderProps) => {
    return `https://img.bricklink.com/${src}`;
  };

  return (
    <>
      <Head>
        <title>Wypożyczalnia klocków</title>
        <meta
          name="description"
          content="Największa wypożyczalnia klocków lego w polsce. Posiadamy ponad 500 różnych zestawów, a nasze magazyny z roku na rok się powiększają! Jesteśmy dostępni w całej Polsce."
        />
        <meta
          name="keyword"
          content="wypożyczalnia klocków, wypożyczalnia klocków lego"
        />
      </Head>
      <Content>
        <div className="container">
          <div
            className="fb-page"
            data-href="https://www.facebook.com/Wypo%C5%BCyczalnia-klock%C3%B3w-110962778088912/"
            data-tabs=""
            data-width=""
            data-height=""
            data-small-header="false"
            data-adapt-container-width="false"
            data-hide-cover="false"
            data-show-facepile="false"
          >
            <blockquote
              cite="https://www.facebook.com/Wypo%C5%BCyczalnia-klock%C3%B3w-110962778088912/"
              className="fb-xfbml-parse-ignore"
            >
              <a href="https://www.facebook.com/Wypo%C5%BCyczalnia-klock%C3%B3w-110962778088912/">
                Wypożyczalnia klocków
              </a>
            </blockquote>
          </div>
        </div>
        <Article borderColor="#21a80f" firstLetterColor="#21a80f">
          <Typography>
            Witamy w największej wypożyczalni zestawów klocków LEGO w polsce!
            Staramy się aby wszystkie zestawy trafiały do państwa w jak
            najlepszej kondycji oraz aby nasz oferta była jak największa!
          </Typography>
        </Article>
        <Article borderColor="#ffa700" firstLetterColor="#ffa700">
          <Typography>
            Na chwilę obecną posiadamy ponad 500 zestawów do wypożyczenia.
            Jeżeli nie znaleźliście państwo interesującego zestawu tutaj, to
            zachęcamy do kontaktu z nami po przez zakładkę kontakt, ponieważ nie
            wszystkie zestawy są wyświetlane na stronie!
          </Typography>
        </Article>
        <Article borderColor="#ff00cb" firstLetterColor="#ff00cb">
          <Typography>
            Przed każdym wypożyczeniem nasze wszystkie klocki są starannie
            czyszczone, liczone oraz segregowane tak jak w oryginalnym
            opakowaniu, aby zapewnić jak najlepszą zabawę!
          </Typography>
        </Article>
        <Article borderColor="#2000ff" firstLetterColor="#2000ff">
          <Typography>Zasady wypożyczenia są bardzo proste.</Typography>
          <Typography>
            Wybierz intersujący Cię zestaw, naciśnij przycisk na ile dni
            ineteresuję Cię wypożyczenie, a następnie wypełnij formularz
            zgłoszeniowy.
          </Typography>
          <Typography>
            Następnie skontaktujemy się z tobą aby potwierdzić rezerwację, a
            następnym etapem będzie wysłanie maila z kolejnymi instrukcjami,
            gdzie należy wysłać nam kaucję + koszt wysyłki
          </Typography>
          <Typography>
            Po zaksięgowaniu wpłaty zestaw zostanie wysłany na podany adres.
          </Typography>
          <Typography>
            Czas wypożyczenia liczymy od odebrana paczki przez Ciebie do momentu
            kiedy zostanie oddana w ręcę kurierowi.
          </Typography>
        </Article>

        <Article borderColor="#21a80f" firstLetterColor="#21a80f">
          <Typography>
            Witamy w największej wypożyczalni zestawów klocków LEGO w Polsce.
            Wszystkie zestawy trafią do Ciebie w idealnym stanie. Codziennie
            pracujemy nad tym aby nasza oferta była coraz większa.
          </Typography>
        </Article>
        <Article borderColor="#ffa700" firstLetterColor="#ffa700">
          <Typography>
            Na chwilę obecną posiadamy ponad 500 zestawów do wypożyczenia.
            Jeżeli nie znalazłeś interesującego Cię zestawu tutaj,{" "}
            <a href="#footer">napisz do nas</a>. Nie wszystkie zestawy są
            wyświetlane na stronie.
          </Typography>
        </Article>
        <Article borderColor="#ff00cb" firstLetterColor="#ff00cb">
          <Typography>
            Przed każdym wypożyczeniem, nasze klocki są starannie czyszczone,
            liczone oraz segregowane tak jak w oryginalnym opakowaniu. Dzięki
            temu możemy zapewnić jak najlepszą zabawę.
          </Typography>
        </Article>
        <Article borderColor="#2000ff" firstLetterColor="#2000ff">
          <Typography>Zasady wypożyczenia są bardzo proste.</Typography>
          <Typography>
            Wybierz intersujący Cię zestaw, naciśnij przycisk na ile dni chcesz
            wypożyczyć klocki. Następnie wypełnij formularz zgłoszeniowy.
          </Typography>
          <Typography>
            Skontaktujemy się z Tobą, w celu potwierdzenia rezerwacji. Kolejnym
            etapem będzie wysłanie maila z instrukcjami, gdzie należy wysłać
            kaucję + koszt wysyłki.
          </Typography>
          <Typography>
            Po zaksięgowaniu wpłaty zestaw zostanie wysłany na podany adres.
          </Typography>
          <Typography>
            Czas wypożyczenia liczony jest od odebrania paczki przez Ciebie, do
            momentu oddania kurierowi.
          </Typography>
        </Article>
      </Content>
    </>
  );
};

export default Home;
